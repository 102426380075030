.navbar {
    background: #1e1e1e;
    padding: 14px 20px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.navbar-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-logo {
    font-size: 1.8rem;
    font-weight: 700;
    color: #facc15;
    text-decoration: none;
}

.navbar-links {
    display: flex;
    gap: 20px;
}

.nav-item {
    font-size: 1rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.nav-item:hover {
    color: #facc15;
}

.nav-item.active {
    color: #facc15;
    font-weight: 600;
}

.resume-button {
    background: #04144a;
    color: black;
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 6px;
    text-decoration: none;
    transition: background 0.3s ease-in-out;
}

.resume-button:hover {
    background: #ffcc33;
}

.menu-toggle {
    display: none;
    font-size: 1.8rem;
    background: none;
    border: none;
    color: #facc15;
    cursor: pointer;
}

.mobile-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #121212;
    position: absolute;
    width: 100%;
    left: 0;
    top: 60px;
    padding: 20px 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.mobile-nav-item {
    font-size: 1.2rem;
    padding: 12px 0;
    color: white;
    text-decoration: none;
    width: 100%;
    text-align: center;
}

.mobile-nav-item:hover {
    color: #facc15;
}

.mobile-resume-button {
    background: #04144a;
    color: black;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 8px;
    text-decoration: none;
    margin-top: 10px;
}

.mobile-resume-button:hover {
    background: #ffcc33;
}

@media (max-width: 768px) {
    .navbar-links, .resume-button {
        display: none; 
    }

    .menu-toggle {
        display: block;
    }
}
