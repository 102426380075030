.education-container {
    max-width: 800px;
    margin: auto;
    padding: 60px 20px;
    text-align: center;
}

.education-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 20px;
}

.education-card {
    background: #1e1e1e;
    border-radius: 12px;
    padding: 20px;
    margin: 15px 0;
    text-align: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.education-card:hover {
    transform: scale(1.03);
    box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.1);
}

.degree-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #facc15;
}

.university {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 5px;
}

.details {
    font-size: 0.95rem;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 5px;
}

@media (max-width: 500px) {
    .education-card {
        width: 100%;
        padding: 20px;
    }
}
