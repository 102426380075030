@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;800&display=swap');

body {
  font-family: 'Inter', sans-serif;
  background-color: #111827;
  color: #e5e7eb;
  margin: 0;
  padding-top: 70px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

a {
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

nav {
  background: rgba(17, 24, 39, 0.9);
  backdrop-filter: blur(10px);
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 50;
}

nav a {
  color: #f3f4f6;
  font-weight: 600;
  margin: 0 15px;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

nav a:hover {
  color: #facc15; 
}

nav a.active {
  color: #facc15;
  background: rgba(250, 204, 21, 0.2);
}

section {
  padding: 80px 16px;
  max-width: 800px;
  margin: auto;
  text-align: center;
}

h1 {
  font-size: 3rem;
  font-weight: 800;
  color: #facc15;
}

h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 10px;
}

p {
  font-size: 1.2rem;
  line-height: 1.6;
}

.text-link {
  color: #3b82f6;
  font-weight: 600;
}

.text-link:hover {
  text-decoration: underline;
  color: #2563eb;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  margin: 10px 0;
  transition: transform 0.3s ease-in-out;
}

li:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.2);
}

.contact a {
  display: inline-block;
  margin: 10px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.contact a:hover {
  color: #facc15;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }

  nav {
    padding: 10px;
  }

  nav a {
    font-size: 14px;
    padding: 6px 12px;
  }
}
