.projects-container {
    max-width: 900px;
    margin: auto;
    padding: 60px 20px;
    text-align: center;
}

.projects-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 20px;
}

.projects-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-content: center;
}

.project-card {
    background: #1e1e1e;
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.project-card:hover {
    transform: scale(1.03);
    box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.1);
}

.project-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #facc15;
}

.project-description {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 10px;
}

.project-tech {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 10px;
}

.project-link {
    display: inline-block;
    margin-top: 12px;
    background: #3b82f6;
    color: white;
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: 600;
    text-decoration: none;
    transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.project-link:hover {
    background: #2563eb;
    transform: scale(1.05);
}

@media (max-width: 600px) {
    .projects-list {
        grid-template-columns: 1fr;
    }
}
