.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
}

.contact-card {
    background: #1e1e1e;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
    width: 400px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.contact-card:hover {
    transform: scale(1.03);
    box-shadow: 0px 10px 25px rgba(255, 255, 255, 0.1);
}

.contact-title {
    font-size: 2rem;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 15px;
}

.contact-info {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.8);
    margin: 8px 0; 
    gap: 6px;
}

.contact-link {
    color: #3b82f6;
    font-weight: 600;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.contact-link:hover {
    color: #2563eb;
}

.contact-icon {
    color: #facc15;
    font-size: 1.2rem;
}

@media (max-width: 500px) {
    .contact-card {
        width: 100%;
        padding: 20px;
    }
}
