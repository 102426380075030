.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: flex-start;
    text-align: center;
    padding-top: 80px; 
}

.profile-card {
    background: #1e1e1e; 
    border-radius: 12px;
    padding: 24px;
    width: 350px;
    text-align: center;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.profile-card:hover {
    transform: scale(1.03);
    box-shadow: 0px 10px 25px rgba(255, 255, 255, 0.1);
}

.profile-image {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.1);
}

.profile-name {
    font-size: 1.8rem;
    font-weight: 700;
    color: #ffffff;
    margin-top: 12px;
}

.profile-title {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.85);
    margin-top: 4px;
    font-weight: 600;
}

.profile-skills {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 4px;
    font-weight: 500;
}

.profile-social-links {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 12px;
}

.profile-social-links a {
    color: rgba(255, 255, 255, 0.6);
    padding: 6px 12px;
    font-weight: 600;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.profile-social-links a:hover {
    color: white;
}

.profile-summary {
    max-width: 700px;
    margin-top: 40px;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.2rem;
    line-height: 1.7;
    padding: 0 20px;
    font-weight: 400;
}

body {
    background: #121212;
}
